import { Interview, InterviewState, InterviewTranscriptState, InterviewUploadedFileState } from 'types/interview';
import { type TranscriptPassage } from 'types/transcript-passage';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const useInterviewStore = defineStore('interviews', {
  state: () => ({
    interviews: [] as Interview[],
    interview: {} as Interview,
    currentInterviewSubscription: null as any,
    interviewSubscriptions: null as any,
    projectId: '' as string,
    uploadProgress: {} as { [key: string]: number },
    uploadProgressCounter: 0 as number,
    db: useFirestore(),
  }),
  
  actions: {
    async getInterviews(projectId: string) {
      if ((!projectId || projectId == this.projectId) && useInterviewStore().interviews.length)
        return;
      if (this.interviewSubscriptions) this.interviewSubscriptions();
      this.projectId = projectId;
      this.interviews = [];
      const projectStore = useProjectStore();
      console.log('fireInterview');
      this.interviewSubscriptions = onSnapshot(
        collection(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews`
        ),
        async (interviewQuerySnapshot) => {
          interviewQuerySnapshot.forEach((fireInterview) => {
            let foundElement = this.interviews.find((i) => i.id == fireInterview.id);
            if (!foundElement){
              foundElement={ id: fireInterview.id, text:'',...(fireInterview.data() as Omit<Interview,'text' | 'id'>) }; 
              foundElement.text=""
              this.interviews.push(foundElement);
            }
            else {
              foundElement.date = fireInterview.data().date;
              foundElement.interviewee = fireInterview.data().interviewee;
              foundElement.text = fireInterview.data().text;
              foundElement.pendingReviews = fireInterview.data().pendingReviews;
              foundElement.state = fireInterview.data().state;
              foundElement.persona = fireInterview.data().persona;
              foundElement.transcriptState = fireInterview.data().transcriptState;
              foundElement.uploadedFileState = fireInterview.data().uploadedFileState;
              foundElement.errorFileUploadMessage= fireInterview.data().errorFileUploadMessage;
              foundElement.errorTranscriptionMessage= fireInterview.data().errorTranscriptionMessage;
            }
          });
        }
      );
    },
    async setCurrentInterview(id: string) {
      const projectStore = useProjectStore();
      if (!this.projectId) this.projectId = useProjectStore().project.id;
      if (this.interview.id != id) this.interview = new Interview();
      const interviewDoc = await getDoc(
        doc(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews/${id}`
        )
      );
      this.interview = { id: interviewDoc.id, ...interviewDoc.data() } as Interview;
      this.interview.transcript_passages = formatInterviewText(
        this.interview.speaker_transcript ?? []
      );
      if (this.interview.transcriptState !== InterviewTranscriptState.FINISHED) {
        this.subscribeInterview();
      }
    },
    async subscribeInterview() {
      if (this.currentInterviewSubscription) {
        this.currentInterviewSubscription();
        this.currentInterviewSubscription = null;
        return;
      }
      this.currentInterviewSubscription = onSnapshot(
        doc(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews/${this.interview.id}`
        ),
        async (interviewSnapshot) => {
          this.interview = { id: interviewSnapshot.id, ...interviewSnapshot.data() } as Interview;
          if (this.interview.transcriptState === InterviewTranscriptState.FINISHED) {
            this.interview.transcript_passages = formatInterviewText(
              this.interview.speaker_transcript ?? []
            );
            await this.currentInterviewSubscription();
            this.currentInterviewSubscription = null;
          }
        }
      );
    },
    async deleteInterview(interviewId: string) {
      const interviewDoc = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews`,
        interviewId
      );
      this.interviews = this.interviews.filter((i) => i.id != interviewId);
      await deleteDoc(interviewDoc);
    },
    async addInterview(projectId: string, interview: Interview) {
      const createdInterview = await addDoc(
        collection(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${projectId}/interviews`
        ),
        {
          speaker_transcript: interview.speaker_transcript ?? [],
          text: interview.text ?? '',
          interviewee: interview.interviewee ?? '',
          date: interview.date,
          state: InterviewState.NO_RECORDING,
          pendingReviews: 0,
          user_id: interview.user_id ?? ''
        }
      );
      return createdInterview.id;
    },
    async IncrementInterviewPendingReviews(pendingReviews: number) {
      this.interview.pendingReviews += pendingReviews;
      updateDoc(
        doc(
          this.db,
          `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews`,
          this.interview.id ?? ''
        ),
        {
          pendingReviews: Math.max(this.interview.pendingReviews, 0)
        }
      );
    },
    async updateInterviewee(interviewId: string, interviewee: string) {
      const interview = this.interviews.find((i) => i.id == interviewId);
      if (!interview || interview.interviewee == interviewee) return;
      this.interview.interviewee = interviewee;
      const interviewRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews/${interviewId}`
      );
      await updateDoc(interviewRef, { interviewee });
    },
    async updateDate(interviewId: string, date: Date) {
      const interview = this.interviews.find((i) => i.id == interviewId);
      if (!interview || interview.date == date) return;
      interview.date = date;

      const interviewRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews/${interviewId}`
      );
      await updateDoc(interviewRef, { date });
    },
    async updatePersona(interviewId: string, persona: string) {
      const interviewRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews/${interviewId}`
      );
      await updateDoc(interviewRef, { persona });
    },
    async addPersona(interviewId: string, persona: string) {
      const interviewRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews/${interviewId}`
      );
      await updateDoc(interviewRef, { persona });
      const workspaceId = useWorkspaceStore().workspace.id;
      const personasRef = collection(
        this.db,
        `/workspaces/${workspaceId}/personas`
      );
      await addDoc(personasRef, { persona });
    },
    async updateUserId(interviewId: string, userId: string) {
      const interviewRef = doc(
        this.db,
        `/workspaces/${useWorkspaceStore().workspace.id}/projects/${this.projectId}/interviews/${interviewId}`
      );
      await updateDoc(interviewRef, { user_id: userId });
    },
    getErrorMessageOnError(interview: Interview | null = null): string | null {
      if (!interview) interview = this.interview;
      
      if(interview.uploadedFileState === InterviewUploadedFileState.ERROR_FILE_CORRUPT) {
        return 'Corrupted file, please retry with another file';
      }
      
      if(interview.transcriptState === InterviewTranscriptState.ERROR_UNKOWN) {
        return 'An error occurred while processing the file';
      }
      return null;
    }
  }
});

const formatInterviewText = (
  passages: { speaker: string; text: string; time: string }[]
): TranscriptPassage[] => {
  const formattedArray = [];
  for (let i = 0; i < passages.length; i++) {
    formattedArray.push({
      start_timestamp: passages[i].time,
      end_timestamp: passages[i].time,
      text: passages[i].text,
      speaker: passages[i].speaker,
      noteIds: []
    });
  }
  return formattedArray;
};
